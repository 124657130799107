import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TruncatePipe } from '../../../../../core/pipes/truncate.pipe';
import { IconComponent } from '../../widgets/icon/icon.component';
import {
  UserListDTO,
  UserMinimalDTO,
} from '../../../../../features/organization/users/dto/user.dto';
import { UserImageComponent } from '../../widgets/user-image/user-image.component';
import { DateTimeFormatPipe } from '../../../../../core/pipes/date-time-format.pipe';
import { ReadMoreComponent } from '../read-more/read-more.component';
import { getStatusLabelClass } from '../../../../../core/modals/status.model';
import { FileDetailsDTO } from '../../../../dto/file.dto';
import { ListItemDTO } from '../../../../../core/modals/details.modal';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-label-display',
  standalone: true,
  imports: [
    TranslateModule,
    TruncatePipe,
    IconComponent,
    UserImageComponent,
    DateTimeFormatPipe,
    ReadMoreComponent,
    NgClass
  ],
  templateUrl: './label-display.component.html',
  styleUrl: './label-display.component.scss',
})
export class LabelDisplayComponent {
  @Input() label: string = '';
  @Input() list: ListItemDTO[];
  @Input() value: string | number | null | undefined = null;
  @Input() type:
    | 'text'
    | 'number'
    | 'para'
    | 'document'
    | 'documents'
    | 'status'
    | 'user'
    | 'users'
    | 'datetime'
    | 'list' = 'text';
  @Input() url: string | undefined;
  @Input() user: UserMinimalDTO | null | undefined = undefined;
  @Input() users: UserMinimalDTO[] | UserListDTO[] | null | undefined =
    undefined;
  @Input() status: any | undefined;
  @Input() document: FileDetailsDTO | null;
  @Input() documents: FileDetailsDTO[] | null;
  @Input() maxLength: number = 250;
  @Input() displayUserCount = 2;
  @Input() userTypeCustomClass: string = '';
  @Input() downloadBaseUrl: string = '';
  @Input() openInNewTab: boolean = true;

  public isExpanded: boolean = false;

  getClass(color: string) {
    return getStatusLabelClass(color);
  }

  isUserMinimalDTO(user: any): user is UserMinimalDTO {
    return 'first_name' in user && 'last_name' in user;
  }

  get hiddenUserCount(): number {
    if (this.users) {
      return this.users.length > this.displayUserCount
        ? this.users.length - this.displayUserCount
        : 0;
    }
    return 0;
  }
}
